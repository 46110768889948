<template>
	<div class="w-100" v-if="all_planning">
		<div class="text-center mb-3"> 
			<h1>{{ $t('monte.planning_monte') }}</h1>
			<h2>{{ lieu.lieu_label }}</h2>
			<h4 v-if="params.date_to">{{ $t('monte.print_from') }} {{ formatDate(params.date_from) }} {{ $t('monte.print_to') }} {{ formatDate(params.date_to) }}</h4>
			<h4 v-else>{{ formatDate(params.date_from) }}</h4>
		</div>
		<template>
			<template v-for="(planning, index) in all_planning">
				<div v-if="params.date_to && planning" :key="index" class="text-center">
					<h3 class="text-center border rounded p-3">{{ index }}</h3>
				</div>
				<template v-if="planning">
					<div class="w-100 text-center calendar_horraire" v-for="(schedule, index2) in planning.planning_schedule" :key="index+'_'+index2">
						<h4>{{ schedule.schedule_start }} - {{ schedule.schedule_end }}</h4>
						<table class="table table-variante w-100">
							<tr>
								<th width="20%">{{ $t('monte.etalon') }}</th>
								<th width="20%">{{ $t('monte.jument') }}</th>
								<th width="10%">{{ $t('monte.horse_transpondeur') }}</th>
								<th width="20%">{{ $t('monte.contact') }}</th>
								<th width="10%">{{ $t('monte.contrat') }}</th>
								<th width="20%">{{ $t('monte.commentaire') }}</th>
							</tr>
							<template v-for="(horse, index3) in planning.planning_horse">
								<template v-if="horse.nb_mare[schedule.schedule_id] > 0">
									<tr :key="index+'_'+index2+'_'+index3">
										<td width="25%" :rowspan="horse.nb_mare[schedule.schedule_id]+1">{{ horse.planninghorse_stallion.horse_nom }}</td>
									</tr>
									<template v-for="(schedule2, index4) in planning.planning_schedule">
										<tr v-if="schedule2.schedule_info[horse.planninghorse_stallion.horse_id] && schedule2.schedule_id == schedule.schedule_id" :key="index+'_'+index2+'_'+index3+'_'+index4">
											<td width="25%">{{ schedule2.schedule_info[horse.planninghorse_stallion.horse_id][0].schedulehorse_mare.horse_nom }}</td>
											<td width="13%">{{ schedule2.schedule_info[horse.planninghorse_stallion.horse_id][0].schedulehorse_mare.horse_transpondeur }}</td>
											<td width="25%">
												<template v-if="schedule2.schedule_info[horse.planninghorse_stallion.horse_id][0].schedulehorse_contact.contact_firstname">
													{{ schedule2.schedule_info[horse.planninghorse_stallion.horse_id][0].schedulehorse_contact.contact_firstname }} {{ schedule2.schedule_info[horse.planninghorse_stallion.horse_id][0].schedulehorse_contact.contact_lastname }}
													<template v-if="schedule2.schedule_info[horse.planninghorse_stallion.horse_id][0].schedulehorse_contact.phones.length">
												 		{{ formatPhone(schedule2.schedule_info[horse.planninghorse_stallion.horse_id][0].schedulehorse_contact.phones[0].phone_combine) }}
													</template>
												</template>
												<template v-if="schedule2.schedule_info[horse.planninghorse_stallion.horse_id][0].schedulehorse_tiers.tiers_rs">
													{{ schedule2.schedule_info[horse.planninghorse_stallion.horse_id][0].schedulehorse_tiers.tiers_rs }}
													<template v-if="schedule2.schedule_info[horse.planninghorse_stallion.horse_id][0].schedulehorse_tiers.phones.length">
												 		{{ formatPhone(schedule2.schedule_info[horse.planninghorse_stallion.horse_id][0].schedulehorse_tiers.phones[0].phone_combine) }}
													</template>
												</template>
											</td>
											<td width="12%">
												<template v-if="!schedule2.schedule_info[horse.planninghorse_stallion.horse_id][0].contrat.avenant_contract">{{ $t('monte.contrat_a_faire') }}</template>
												<template v-else-if="schedule2.schedule_info[horse.planninghorse_stallion.horse_id][0].contrat.avenant_status == 3">{{ $t('monte.contrat_signe') }}</template>
												<template v-else>{{ $t('monte.contrat_a_signe') }}</template>
											</td>
											<td>
												{{ schedule2.schedule_info[horse.planninghorse_stallion.horse_id][0].schedulehorse_commentaire }}
											</td>
										</tr>
									</template>
								</template>
							</template>
						</table>
					</div>
				</template>
			</template>
		</template>
	</div>
</template>

<script type="text/javascript">
	import Vue from 'vue'
    import PlanningMonte from "@/mixins/PlanningMonte.js"
    import Gynecologie from "@/mixins/Gynecologie.js"
    import _cloneDeep from "lodash/cloneDeep"
    import Common from '@/assets/js/common.js'
	import Contract from '@/mixins/Contract'
    import Lieux from "@/mixins/Lieux.js"
	import { parsePhoneNumberFromString as parseMax, AsYouType } from 'libphonenumber-js/max'

	export default {
		name: 'PrintCalendarMain',
		props:['readyToPrint', 'params'],
		mixins: [PlanningMonte, Gynecologie, Contract, Lieux],
		data () {
			return {
				season: {},
				all_planning: {},
				date_from: null,
				date_to: null,
				lieu: {}
			}
		},

		mounted() {
			this.init_component()
		},

		methods: {
			async init_component() {
				this.date_from = this.params.date_from
				if(this.params.date_to) {
					this.date_to = this.params.date_to
				}
				this.season = await this.getCurrentSeason(this.date_from)
				this.lieu = await this.getLieuById(this.params.lieu_id)
				this.loadPlanning()
			},

			async loadPlanning() {
				if(this.season.length != 0){
					if(this.params.date_to) {
						let loop = new Date(this.params.date_from)

						while(loop <= this.params.date_to) {
							const formatted = this.formatDate2(loop)
							this.all_planning[formatted] = await this.getPlanning(this.season[0].season_id, "main", loop, this.params.lieu_id)

							// nombre de juments par étalon et par créneau pour le rowspan
							this.all_planning[formatted].planning_horse.forEach(horse => {
								horse.nb_mare = {}
								this.all_planning[formatted].planning_schedule.forEach(schedule => {
									horse.nb_mare[schedule.schedule_id] = 0
									if(schedule.schedule_info[horse.planninghorse_stallion.horse_id]){
										horse.nb_mare[schedule.schedule_id] = schedule.schedule_info[horse.planninghorse_stallion.horse_id].length
									}
								})
							})

							// pour chaque jument de chaque créneau, récupération de l'état physio et du contrat
							await Common.asyncForEach(
								this.all_planning[formatted].planning_schedule,
								async (schedule) => {
									for(let stallion in schedule.schedule_info) {
										schedule.schedule_info[stallion][0].contrat = {avenant_status: 0}
										if(schedule.schedule_info[stallion][0].schedulehorse_mare.horse_id) {
											const season_mare = await this.loadSeasonMareStallionBySeasonHorse(schedule.schedule_info[stallion][0].schedulehorse_mare.horse_id, this.season[0].season_id)
											if(season_mare.length > 0) {
												const status = await this.loadMareStatus()
												const mare_status = status.find(stat => stat.marestatus_id == season_mare[0].seasonmare_status)
												if(mare_status)
													schedule.schedule_info[stallion][0].schedulehorse_mare.mare_status = mare_status.marestatus_code
											}


											const contrats = await this.loadContracts(schedule.schedule_info[stallion][0].schedulehorse_mare.horse_id)

											const avenant = contrats.filter(avenant => {
												if(avenant.contract.length === 0) return false
												if(avenant.contract.config.length === 0) return false
												if(!avenant.contract.config.horse) return false
												return avenant.contract.config.horse.horse_id == stallion
												&& avenant.contract.config.season.season_id == this.season[0].season_id
												&& avenant.avenant_id == avenant.contract.avenant.avenant_id
											})

											if(avenant.length > 0) {
												schedule.schedule_info[stallion][0].contrat = avenant[0];
											}
										}
									}
								})

							let newDate = loop.setDate(loop.getDate() + 1)
							loop = new Date(newDate)
						}
					} else {
						this.all_planning[this.date_from] = await this.getPlanning(this.season[0].season_id, "main", this.date_from, this.params.lieu_id)

						// nombre de juments par étalon et par créneau pour le rowspan
						this.all_planning[this.date_from].planning_horse.forEach(horse => {
								horse.nb_mare = {}
								this.all_planning[this.date_from].planning_schedule.forEach(schedule => {
									horse.nb_mare[schedule.schedule_id] = 0
									if(schedule.schedule_info[horse.planninghorse_stallion.horse_id]){
										horse.nb_mare[schedule.schedule_id] = schedule.schedule_info[horse.planninghorse_stallion.horse_id].length
									}
								})
							})

							// pour chaque jument de chaque créneau, récupération de l'état physio et du contrat
							await Common.asyncForEach(
								this.all_planning[this.date_from].planning_schedule,
								async (schedule) => {
									for(let stallion in schedule.schedule_info) {
										schedule.schedule_info[stallion][0].contrat = {avenant_status: 0}
										if(schedule.schedule_info[stallion][0].schedulehorse_mare.horse_id) {
											const season_mare = await this.loadSeasonMareStallionBySeasonHorse(schedule.schedule_info[stallion][0].schedulehorse_mare.horse_id, this.season[0].season_id)
											if(season_mare.length > 0) {
												const status = await this.loadMareStatus()
												const mare_status = status.find(stat => stat.marestatus_id == season_mare[0].seasonmare_status)
												if(mare_status)
													schedule.schedule_info[stallion][0].schedulehorse_mare.mare_status = mare_status.marestatus_code
											}

											const contrats = await this.loadContracts(schedule.schedule_info[stallion][0].schedulehorse_mare.horse_id)

											const avenant = contrats.filter(avenant => {
												if(avenant.contract.length === 0) return false
												if(avenant.contract.config.length === 0) return false
												return avenant.contract.config.horse.horse_id == stallion
												&& avenant.contract.config.season.season_id == this.season[0].season_id
												&& avenant.avenant_id == avenant.contract.avenant.avenant_id
											})

											if(avenant.length > 0) {
												schedule.schedule_info[stallion][0].contrat = avenant[0];
											}
										}
									}
								})
					}
				}
				const planning_copy = _cloneDeep(this.all_planning)
				this.$set(this.all_planning, planning_copy)

				this.readyToPrint()	
			},

			formatDate(date) {
				return date.getDate() + '/' + parseInt(date.getMonth()+1) + '/' + date.getFullYear()
			},

			formatDate2(date) {
				const formatter = new Intl.DateTimeFormat(Vue.i18n.locale(), { month: 'long' });
				return date.getDate() + ' ' + formatter.format(date) + ' ' + date.getFullYear()
			},

			formatPhone(number) {
				return new AsYouType(Vue.i18n.locale()).input(number)
			}
		},

		components: {
			CellCalendarMain : () => import('@/components/Monte/CellCalendarMain'),
		}
	}

</script>